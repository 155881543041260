import React, { FC, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import 'firebase/auth';
import * as firebase from 'firebase';
import * as firebaseui from 'firebaseui'
import { StyledFirebaseAuth } from 'react-firebaseui';
import { firebaseConfig } from './firebaseConfig';
import { Box, Modal } from '@material-ui/core';

// components
import { Nav } from './components/nav'
import { Home } from './containers/home';

// css
import './App.css';

export interface IUser {
  email?: string | null;
  uid?: string;
  role?: ("dev" | "admin" | "user")[]
  congregationId?: string;
  displayName: string | null

}

interface ICongregation {
  id?: string
  name?: string
}

// config for firebase login ui
const authUiConfig = {
  signInFlow: 'popup',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: true,
    }
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult: firebase.auth.UserCredential) => {
      return true
    }
  }
}


const App: FC = () => {
  const history = useHistory()
  const location = useLocation()

  // state
  const [user, setUser] = useState<IUser | null>(null)
  const [congregation, setCongregation] = useState<ICongregation | null>(null)


  // initialize firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  // initialize firebase auth
  const firebaseAuth = firebase.auth()
  const db = firebase.firestore()

  /**
   * fetch the current user's metadata
   * @param userToLookup firebase user object
   */
  const fetchUserMeta = (userToLookup: firebase.User) => {
    // lookup if user is already created
    db.collection("users")
      .doc(userToLookup.uid)
      .get()
      .then((doc) => {
        const result = doc.data();
        if (!result) {
          // if there is no user created, check invites, and create a user if invited
          verifyAndCreateUser(userToLookup)
          return;
        }
        setUser({
          email: userToLookup.email,
          uid: userToLookup.uid,
          congregationId: result.congregationId,
          role: result.role,
          displayName: userToLookup.displayName
        })
        fetchCongregation(result.congregationId)

      })
      .catch(err => console.log(err))
  }


  /**
   * function to fetch congregation data
   * @param congregationId id of congregation to lookup
   */
  const fetchCongregation = (congregationId: string | null) => {
    if (!congregationId) {
      setCongregation(null)
      return;
    }
    db.collection("congregations")
      .doc(congregationId)
      .get()
      .then(res => {
        const cong = res.data()
        if (cong) {
          setCongregation({ id: congregationId, name: cong.name })
        }
      })
      .catch(err => console.log({ err }))
  }


  const verifyAndCreateUser = (newUser: firebase.User) => {
    if (!newUser.email) {
      return
    }
    db.collection("userInvites")
      .where("email", "==", newUser.email)
      .limit(1)
      .get()
      .then(res => {
        res.forEach(invite => {
          const inviteItem = invite.data()
          db.collection("users")
            .doc(newUser.uid)
            .set({
              congregationId: inviteItem.congregationId,
              role: inviteItem.role
            })
          setUser({
            displayName: newUser.displayName,
            congregationId: inviteItem.congregationId,
            role: inviteItem.role,
            email: inviteItem.email,
            uid: newUser.uid
          })
          if (inviteItem.status === "sent") {
            invite.ref.set({
              status: "accepted"
            }, { merge: true })
          }
        })
      })
      .catch(err => console.dir({ err }))
  }

  // user observer to trigger render on user state change
  firebaseAuth.onAuthStateChanged((newUser) => {

    // if user has changed to null:
    if (!newUser) {
      setUser(null)
      setCongregation(null)
      if (location.pathname !== "/") {
        history.push("/")
      }
      return
    }

    // check if its the same user
    if (newUser?.uid !== user?.uid) {
      setUser({
        email: newUser.email,
        uid: newUser.uid,
        displayName: newUser.displayName
      })
      fetchUserMeta(newUser)
    }
  })

  return (
    <div className="App">
      <Nav auth={firebaseAuth} />
      <Modal open={!user}>
        <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
          <StyledFirebaseAuth
            uiConfig={authUiConfig}
            firebaseAuth={firebaseAuth} />
        </Box>
      </Modal>
      <Switch>
        <Route exact={true} path="/" render={() => user && <Home user={user} congregationId={congregation?.id} db={db} />} />
      </Switch>
    </div>
  );
}

export default App;
