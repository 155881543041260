import React, { FC } from 'react'
import { Typography, Box } from '@material-ui/core'
import { IUser } from '../../App'

interface IHome {
    user: IUser
    congregationId?: string
    db: firebase.firestore.Firestore
}

export const Home: FC<IHome> = ({ user }) => {

    return (
        <Box m={4}>
            <Typography>Welcome {user.displayName} </Typography>
        </Box>
    )
}