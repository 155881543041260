import React, { FC } from 'react'
import * as firebase from 'firebase/app';
import AppBar from '@material-ui/core/AppBar';
import { Toolbar, IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { AccountCircle } from '@material-ui/icons';
import "./nav.css";

interface INav {
    auth: firebase.auth.Auth
}

export const Nav: FC<INav> = ({ auth }) => {
    const user = auth.currentUser
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const signout = () => {
        console.log("bye")
        handleClose();
        auth.signOut()
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <AppBar position="sticky">
            <Toolbar >
                <IconButton edge="start" color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                {user && <div>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => signout()} >Sign out</MenuItem>
                    </Menu>
                </div>}
            </Toolbar>
        </AppBar>
    )
}
